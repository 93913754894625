
import BButton from 'buetify/lib/components/button/BButton';
import BDropdown from 'buetify/lib/components/dropdown/BDropdown';
import BDropdownDivider from 'buetify/lib/components/dropdown/BDropdownDivider';
import BDropdownItem from 'buetify/lib/components/dropdown/BDropdownItem';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'dropdown-simple-example',
	components: {
		BDropdown,
		BDropdownItem,
		BDropdownDivider,
		BButton
	}
});
